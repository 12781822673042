.containerMap {
  width: 100%;
  height: 89%;
}


.inputContainer {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.addressField {
  width: 100%;
  padding: 0 38px !important;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputIcon {
  position: absolute;
  left: 10px;
  top: 40%;
  transform: translateY(-50%);
  font-size: 18px;
  pointer-events: none; 
  color: #888;
}

.addressField {
  padding-left: 50px;
}

.address {
    box-sizing: border-box;
    width: 240px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    margin-top: 12px;
  }

  .addressField {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
  }
  