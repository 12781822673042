.container-custom-event {
    height: 90vh;
    overflow-y: scroll;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: -25px;
}

/* .container-custom-event::-webkit-scrollbar {
    display: none;
} */

.container-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(168, 168, 168);
    border-radius: 8px;
    margin-top: 20px;
    padding: 18px 19px !important;
}

.container-form:hover {
    border: 2px solid purple;
    border-radius: 8px;
    transition: 0.5s;
}

.dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-image: url('/src/assets/add_event.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f9f9f9;
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    width: 100%;
    box-shadow: 11px -12px 8px rgba(0, 0, 0, 0.2);
}

.dropzone:hover {
    border: 2px dashed purple;
    box-shadow: 11px -12px 8px rgba(0, 0, 0, 0.2), inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
}

.position-input-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: #6b757e !important;
    width: 70%;
    flex: unset !important;
    flex-grow: unset !important;
    flex-shrink: unset !important;
    flex-basis: unset !important;
}

.position-input-dropzone:hover {
    cursor: pointer;
}

.ilustration-img {
    width: 272px;
    height: 272px;
    border-color: purple;
    margin: 6px !important;
}

.inputs-hours {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.space {
    margin-bottom: 20px;
    margin-top: 20px;
}

.w-100 {
    width: 100%;
}
.w-50 {
    width: 50% !important;
}

.w-90{
    width: 90% !important;
}

.h-100 {
    height: 100%;
}

.w-33 {
    width: 33%;
}

.action-buttons-custom-event {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 2%;
}